import './assets/global.scss'
import { BrowserRouter, Routes, Route, Outlet, Navigate } from 'react-router-dom'
import ThemeProvider from './context/theme'
import Login from './pages/auth/login'
import Callback from './pages/auth/callback'
import UserProvider from './context/user'
import Home from './pages/home'
import Logout from './pages/auth/logout'
import { BusinessType } from './library/constant'
import CategoryType from './pages/category-type'
import CategoryTypeEditor from './pages/category-type/_id'
import Category from './pages/category'
import CategoryEditor from './pages/category/_id'
import Dialog from './components/common/Dialog'
import Loading from './components/common/Loading'
import Product from './pages/product'
import ProductEditor from './pages/product/_id'
import Alert from './components/common/Alert'
import Room from './pages/room'
import RoomEditor from './pages/room/_id'
import Settings from './pages/settings'
import Page from './pages/page'
import PageEditor from './pages/page/_id'
import Blog from './pages/blog'
import BlogEditor from './pages/blog/_id'
import Menu from './pages/menu'
import MenuEditor from './pages/menu/_id'
import PageNotFound from './layouts/404'
import Reservation from './pages/reservation'
import ReservationEditor from './pages/reservation/_id'
import Order from './pages/order'
import OrderEditor from './pages/order/_id'
import Register from './pages/auth/register'
import Verify from './pages/auth/verify'
import Thankyou from './pages/auth/thankyou'
import Banner from './pages/banner'
import BannerEditor from './pages/banner/_id'
import Media from './pages/media'
import MediaEditor from './pages/media/_id'
import { query } from './library/apolloClient'
import { CHECK_LOGIN } from './graphql'
import { useEffect, useState } from 'react'


const PrivateRoutes = () => {
  const [isPrivate, setPrivate] = useState("")
  query(CHECK_LOGIN, {}, {}).then(({data, errors}) => {
    if(data?.CheckLogin){
      setPrivate("1")
    }else{
      setPrivate("-1")
    }
  })
  if(isPrivate == ""){
    return <div className='flex justify-center items-center fixed h-screen w-screen z-50 bg-black/70 text-[36px] text-white font-bold'>Loading....</div>
  } else if (isPrivate == "1") {
    return <Outlet />
  }
  return <Navigate to="/login" />
}

const mini_shop = () => (
  <>
    <Route path='' element={<Home />} />
    <Route path='/category-type' element={<CategoryType />} />
    <Route path='/category-type/:id' element={<CategoryTypeEditor />} />
    <Route path='/category' element={<Category />} />
    <Route path='/category/:id' element={<CategoryEditor />} />
    <Route path='/banner' element={<Banner />} />
    <Route path='/banner/:id' element={<BannerEditor />} />
    <Route path='/menu' element={<Menu />} />
    <Route path='/menu/:id' element={<MenuEditor />} />
    <Route path='/product' element={<Product />} />
    <Route path='/product/:id' element={<ProductEditor />} />
    <Route path='/page' element={<Page />} />
    <Route path='/page/:id' element={<PageEditor />} />
    <Route path='/order' element={<Order />} />
    <Route path='/order/:id' element={<OrderEditor />} />
    <Route path='/media' element={<Media />} />
    <Route path='/media/:id' element={<MediaEditor />} />
    <Route path='/setting' element={<Settings />} />
  </>
)

const blogger = () => (
  <>
      <Route path='' element={<Home />} />
      <Route path='/category-type' element={<CategoryType />} />
      <Route path='/category-type/:id' element={<CategoryTypeEditor />} />
      <Route path='/category' element={<Category />} />
      <Route path='/category/:id' element={<CategoryEditor />} />
      <Route path='/banner' element={<Banner />} />
      <Route path='/banner/:id' element={<BannerEditor />} />
      <Route path='/menu' element={<Menu />} />
      <Route path='/menu/:id' element={<MenuEditor />} />
      <Route path='/page' element={<Page />} />
      <Route path='/page/:id' element={<PageEditor />} />
      <Route path='/blog' element={<Blog />} />
      <Route path='/blog/:id' element={<BlogEditor />} />
      <Route path='/media' element={<Media />} />
      <Route path='/media/:id' element={<MediaEditor />} />
      <Route path='/setting' element={<Settings />} />
    </>
)

const routes = (type: BusinessType) => {
  switch(type){
    case BusinessType.MINISHOP:
      return mini_shop()
    case BusinessType.BLOGGER:
      return blogger()
    default:
      return (
        <>
          <Route path='' element={<Home />} />
          <Route path='/category-type' element={<CategoryType />} />
          <Route path='/category-type/:id' element={<CategoryTypeEditor />} />
          <Route path='/category' element={<Category />} />
          <Route path='/category/:id' element={<CategoryEditor />} />
          <Route path='/banner' element={<Banner />} />
          <Route path='/banner/:id' element={<BannerEditor />} />
          <Route path='/menu' element={<Menu />} />
          <Route path='/menu/:id' element={<MenuEditor />} />
          <Route path='/product' element={<Product />} />
          <Route path='/product/:id' element={<ProductEditor />} />
          <Route path='/room' element={<Room />} />
          <Route path='/room/:id' element={<RoomEditor />} />
          <Route path='/page' element={<Page />} />
          <Route path='/page/:id' element={<PageEditor />} />
          <Route path='/blog' element={<Blog />} />
          <Route path='/blog/:id' element={<BlogEditor />} />
          <Route path='/order' element={<Order />} />
          <Route path='/order/:id' element={<OrderEditor />} />
          <Route path='/reservation' element={<Reservation />} />
          <Route path='/reservation/:id' element={<ReservationEditor />} />
          <Route path='/media' element={<Media />} />
          <Route path='/media/:id' element={<MediaEditor />} />
          <Route path='/setting' element={<Settings />} />
        </>
      )
  }
}

export default function App(){
  const type = JSON.parse(window.localStorage.getItem("profile") || "{}").tenant?.type;
  return (
    <ThemeProvider>
      <UserProvider>
        <Dialog />
        <Alert />
        <Loading />
        <BrowserRouter >
          <Routes>
            <Route element={<PrivateRoutes />}>
              { routes(type) }        
            </Route>
            <Route path='/signup' element={<Register />} />
            <Route path='/signup/verify' element={<Verify />} />
            <Route path='/thank-you' element={<Thankyou />} />
            <Route path='/login' element={<Login />} />
            <Route path='/callback' element={<Callback />} />
            <Route path='/logout' element={<Logout />} />
            <Route path='*' element={<PageNotFound />} />
          </Routes>
        </BrowserRouter>
      </UserProvider>
    </ThemeProvider>
  )
}