import { createContext, useState } from "react";
import { UserManager, WebStorageStateStore } from 'oidc-client'
export const UserContext = createContext([]);

globalThis.userManager = new UserManager({
    authority: "",
    client_id: "",
    redirect_uri: `${window.location.origin}/callback`,
    response_type: 'id_token token',
    scope: 'openid profile email address roles identityserver4api country subscriptionlevel offline_access',
    stateStore: new WebStorageStateStore({ store: window.sessionStorage })
});

export default function UserProvider(props: {children: any}){
    const [user, setUser] = useState(window.localStorage.getItem("profile") ? JSON.parse(window.localStorage.getItem("profile")) : null)
    
    return (
        <UserContext.Provider value={[{user, setUser}]}>
            {props.children}
        </UserContext.Provider>
    )
}
