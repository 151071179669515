export const API_ENDPOINT = ""
export const LIMIT = 50;

export enum BusinessType {
    MINISHOP = "mini-shop",
    BLOGGER = "blogger"
};

export const LANGUAGES = [
    {title: "English", code: "en"},
    {title: "French", code: "fr"},
    {title: "German", code: "de"},
    {title: "Chinese", code: "cn"},
    {title: "Spanish", code: "es"},
    {title: "Dutch", code: "nl"},
    {title: "Italian", code: "it"},
    {title: "Japanese", code: "jp"},
    {title: "Arabic", code: "ar"},
    {title: "Urdu", code: "ur"}
].sort();
