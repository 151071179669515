import {ApolloClient, InMemoryCache, HttpLink, from} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { API_ENDPOINT } from './constant';

const errorLink = onError((errors) => {
    // console.log(errors)
    // console.log("apollo errors")
    // console.log(graphQLErrors, networkError)
    // console.log("apollo errors")
    // if (graphQLErrors)
    //   graphQLErrors.forEach(({ message, locations, path }) =>
    //     console.log(
    //       `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
    //     )
    //   );
    // if (networkError) console.log(`[Network error]: ${networkError}`);
  });

  
  const client = () => {
    const headers = {};
    const _profile = window.localStorage.getItem("profile") ? JSON.parse(window.localStorage.getItem("profile")) : null;
    if(_profile){
        if(_profile.token){
            headers["Authorization"] = `Bearer ${_profile.token}`
        }
        headers["x-tenant"] = _profile.tenant._id;
    }
    const httpLink = new HttpLink({ uri: `${API_ENDPOINT}/graphql`, headers })
    const _cache = new InMemoryCache({ addTypename: false })
    return new ApolloClient({ link: from([errorLink, httpLink]), cache: _cache });
}



export const query = async (query, variables, returnData) => {
    try{
        const { data, errors, error } = await client().query({query, variables, fetchPolicy: "network-only", canonizeResults: true })
        return {data, errors, error}
    }catch(err){
        return returnData
    }

}


export const mutation = async (mutation, variables, returnData) => {
    try{
        const { data, errors } = await client().mutate({ mutation, variables, fetchPolicy: "no-cache" })
        return {data, errors}
    }catch(err){
        return returnData
    }
}
